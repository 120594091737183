*, *::before, *::after {
  box-sizing: border-box;
}

.btn1 {
  background-color: var(--background-color);
  color: #222;
  padding: .5em 1em;
  border: none;
  outline: none;
  position: relative;
  cursor: pointer;
  border-radius: 0.5rem;
  
  --background-color: #E3E3E3;
  --border-size: 2px;
  --accent-color: #0AF;

}

.btn1.btn-border-pop::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border: var(--border-size) solid var(--background-color);
  transition: top, left, right, bottom, 100ms ease-in-out;
}

.btn1.btn-border-pop:hover::before,
.btn1.btn-border-pop:focus::before {
  top: calc(var(--border-size) * -2);
  left: calc(var(--border-size) * -2);
  right: calc(var(--border-size) * -2);
  bottom: calc(var(--border-size) * -2);
}




.btn1.btn-background-slide::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background: var(--blue-clear);
  border-radius: 0.5rem;

  transition: transform 300ms ease-in-out;
  transform: scaleX(0);
  transform-origin: right;
}

.btn1.btn-background-slide:hover::before,
.btn1.btn-background-slide:focus::before {
  transform: scaleX(1);
}

.btn1.btn-background-slide {
  transition: color 300ms ease-in-out;
  z-index: 1;
}


.btn1.btn-background-slide:hover,
.btn1.btn-background-slide:focus {
  color: white;
}


.btn1.btn-background-circle::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: var(--background-color);
  border-radius: 50%;
  transition: transform 500ms ease-in-out;

  transform: scale(1.5);
}

.btn1.btn-background-circle:hover::before,
.btn1.btn-background-circle:focus::before {
  transform: scale(0);
}

.btn1.btn-background-circle {
  z-index: 1;
  overflow: hidden;
  background-color: var(--accent-color);
  transition: color 500ms ease-in-out;
}

.btn1.btn-background-circle:hover,
.btn1.btn-background-circle:focus {
  color: white;
}

.btn1.btn-border-underline::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: var(--border-size);
  background-color: var(--accent-color);
  transition: transform 300ms ease-in-out;

  transform: scaleX(0);
}

.btn1.btn-border-underline:hover::before,
.btn1.btn-border-underline:focus::before {
  transform: scaleX(1);
}