.accordion{
   width: 1000px;
   margin: auto;
}

.Accordion-card-header{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.Accordion-card{
    border-radius: 0px !important;	
}

.accordionIcon{
    position: absolute;
    right: 0;
    top: 15px;
}

@media (max-width: 415px) {
    .accordion{
   width: 300px;
   margin: auto;
}
}